import "./App.css";
import WertWidget from "@wert-io/widget-initializer";
import React from "react";

function App() {
  // var address = createBrowserHistory().location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  var address = queryParams.get("address");
  console.log("address = ");
  console.log(address);
  // address = address.substr(1,address.length);

  if (address != null && address.startsWith("tz") && address.length === 36) {
    // Parameters for wertUI initialization
    const wertParams = {
      partner_id: "01FG3WGQT0E33RJJQ1E88MKCR4",
      address: address,
      origin: "https://widget.wert.io",
      currency: "USD",
      commodity: "XTZ",
      commodities: "XTZ",
      color_background: "#000000",
      color_buttons: "#6923E7",
      buttons_border_radius: "8",
      color_main_text: "#FFFFFF",
      color_secondary_text: "#958E99",
      color_secondary_buttons: "#29282A",
      color_icons: "#958E99",
      color_links: "#6923E7",
    };
    const wertWidget = new WertWidget(wertParams);
    return (
      <div className="App">
        <header className="App-header">
          <iframe
            src={wertWidget.getEmbedUrl()}
            allow="camera;"
            title="Wert"
            style={{ height: "100vh", width: "100%", border: "0px" }}
          ></iframe>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <h1>You seem have enterd a wrong wallet address</h1>
      </div>
    );
  }
}

export default App;
